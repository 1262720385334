import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        {
          path: "login",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
        {
          path: "oauth",
          component: () =>
            import(/* webpackChunkName: "login" */ "./views/oauth/PickWorkspace.vue"),
        },
        {
          path: "consent",
          component: () => import(/* webpackChunkName: "login" */ "./views/oauth/Consent.vue"),
        },
        {
          path: "tos",
          component: () => import(/* webpackChunkName: "login" */ "./views/Tos.vue"),
        },
        {
          path: "callback",
          component: () => import(/* webpackChunkName: "login" */ "./views/oauth/Callback.vue"),
        },
        {
          path: "create-workspace-token",
          component: () =>
            import(
              /* webpackChunkName: "login" */ "./views/main/workspaces/settings/CreateWorkspaceToken.vue"
            ),
        },
        {
          path: "register/:token",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "./views/Register.vue"),
        },
        {
          path: "register",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "./views/Register.vue"),
        },
        // {
        //   path: "shared/:token",
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "login" */ "./views/Shared.vue"),
        // },
        {
          path: "recover-password",
          component: () =>
            import(/* webpackChunkName: "recover-password" */ "./views/PasswordRecovery.vue"),
        },
        {
          path: "reset-password",
          component: () =>
            import(/* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"),
        },
        {
          path: "new-account-password",
          component: () =>
            import(/* webpackChunkName: "reset-password" */ "./views/NewAccountPassword.vue"),
        },
        {
          path: "main",
          component: () => import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            {
              path: "dashboard",
              component: () =>
                import(/* webpackChunkName: "main-dashboard" */ "./views/main/Dashboard.vue"),
            },
            {
              path: "profile",
              component: () =>
                import(/* webpackChunkName: "main-profile" */ "./views/main/profile/User.vue"),
              redirect: "profile/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "./views/main/profile/UserProfile.vue"
                    ),
                },
                {
                  path: "edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "./views/main/profile/UserProfileEdit.vue"
                    ),
                },
                {
                  path: "password",
                  component: () => import("./views/main/profile/UserProfileEditPassword.vue"),
                },
              ],
            },
            {
              path: ":workspaceid/",
              component: () =>
                import(/* webpackChunkName: "model" */ "./views/main/workspaces/Workspaces.vue"),
              redirect: ":workspaceid/settings/api",
              children: [
                {
                  path: "analytics",
                  component: () =>
                    import(/* webpackChunkName: "model" */ "./views/main/analytics/Analytic.vue"),
                  redirect: ":workspaceid/analytics/view",
                  children: [
                    {
                      path: "view",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/analytics/AnalyticsView.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "analyticsv2",
                  component: () =>
                    import(
                      /* webpackChunkName: "model" */ "./views/main/superanalytics/Analytic.vue"
                    ),
                  redirect: ":workspaceid/analytics/view",
                  children: [
                    {
                      path: "view",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/superanalytics/AnalyticsView.vue"
                        ),
                    },
                    {
                      path: "embed/:embedded_id", // Dynamic segment for embedded_id
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/superanalytics/SupersetEmbed.vue"
                        ),
                    },
                    {
                      path: "edit",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/superanalytics/SupersetEdit.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "settings/",
                  component: () =>
                    import(
                      /* webpackChunkName: "model-label" */ "./views/main/workspaces/WorkspaceView.vue"
                    ),
                  children: [
                    // {
                    //   path: "shared",
                    //   name: "shared",
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "model-newlabel" */ "./views/main/workspaces/settings/Shared.vue"
                    //     ),
                    // },
                    {
                      path: "api",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/workspaces/settings/Api.vue"
                        ),
                    },
                    // {
                    //   path: "billing",
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "model-view" */ "./views/main/workspaces/settings/Subscription.vue"
                    //     ),
                    // },
                    {
                      path: "users",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-newlabel" */ "./views/main/workspaces/settings/Users.vue"
                        ),
                    },
                    {
                      path: "create",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-newlabel" */ "./views/main/workspaces/settings/CreateWorkspace.vue"
                        ),
                    },
                  ],
                },
                {
                  path: "generative",
                  component: () =>
                    import(/* webpackChunkName: "model" */ "./views/main/models/Models.vue"),
                  redirect: ":workspaceid/generative/view",
                  children: [
                    {
                      path: "view",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/models/ModelsViewGenerative.vue"
                        ),
                    },
                    {
                      path: "select",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/models/ModelSelect.vue"
                        ),
                    },
                    {
                      path: "upload",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/models/ModelsUploadGenerative.vue"
                        ),
                    },

                    {
                      path: ":id",
                      component: () =>
                        import(/* webpackChunkName: "model" */ "./views/main/models/Model.vue"),
                      redirect: ":workspaceid/generative/:id/dashboard",
                      children: [
                        {
                          path: "dashboard",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-label" */ "./views/main/models/ModelViewGenerative.vue"
                            ),
                          children: [
                            {
                              path: "dashboard",
                              component: () =>
                                import(
                                  "./views/main/models/labelcontainer/DashboardGenerative.vue"
                                ),
                            },

                            {
                              path: "prompt_tuning",
                              component: () => import("./views/main/models/PromptTuningView.vue"),
                            },
                            {
                              path: "prompt_responses",
                              component: () => import("./views/main/models/PromptResponses.vue"),
                            },
                            // {
                            //   path: 'usage',
                            //   component: () => import('./views/main/models/PromptResponses.vue'),
                            // },

                            {
                              path: "api",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/Api.vue"
                                ),
                            },

                            {
                              path: "update",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/UpdateGenerativeModel.vue"
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "classification",
                  component: () =>
                    import(/* webpackChunkName: "model" */ "./views/main/models/Models.vue"),
                  redirect: ":workspaceid/classification/view",
                  children: [
                    {
                      path: "view",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/models/ModelsViewClassification.vue"
                        ),
                    },

                    {
                      path: "upload",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/models/ModelsUpload.vue"
                        ),
                    },

                    {
                      path: ":id/label",
                      name: "idlabeling",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-label" */ "./views/main/models/LabelingView.vue"
                        ),
                      children: [
                        {
                          path: "labels/new",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/CreateLabel.vue"
                            ),
                        },
                        {
                          path: "labels/:labelid/update",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/UpdateLabel.vue"
                            ),
                        },
                        {
                          path: "labelcontainers/new",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/CreateLabelContainer.vue"
                            ),
                        },
                        {
                          path: "labelcontainers/:labelcontainerid",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/UpdateLabelContainer.vue"
                            ),
                          children: [
                            {
                              path: "labels/:labelid",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/UpdateLabel.vue"
                                ),
                            },
                          ],
                        },
                      ],
                    },

                    {
                      path: ":id",

                      component: () =>
                        import(/* webpackChunkName: "model" */ "./views/main/models/Model.vue"),
                      redirect: ":workspaceid/classification/:id/dashboard",
                      children: [
                        {
                          path: "dashboard",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-label" */ "./views/main/models/ModelView.vue"
                            ),
                          children: [
                            {
                              path: "duplicate",
                              name: "duplicate",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/Duplicate.vue"
                                ),
                            },
                            {
                              path: "dashboard",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/Dashboard.vue"
                                ),
                            },

                            {
                              path: "api",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/Api.vue"
                                ),
                            },
                            {
                              path: "labelcontainers/:labelcontainerid",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/UpdateModelLabelContainer.vue"
                                ),
                              children: [
                                {
                                  path: "labels/:labelid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/UpdateLabel.vue"
                                    ),
                                },
                                {
                                  path: "label",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/CreateLabel.vue"
                                    ),
                                },
                              ],
                            },
                            {
                              path: "metrics",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-metrics" */ "./views/main/models/labelcontainer/Metrics.vue"
                                ),

                              children: [
                                {
                                  path: "preview",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-preview" */ "./views/main/models/labelcontainer/Preview.vue"
                                    ),
                                },
                                {
                                  path: ":labelid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/MetricsMultilabel.vue"
                                    ),
                                  children: [
                                    {
                                      path: "preview",
                                      component: () =>
                                        import(
                                          /* webpackChunkName: "model-preview" */ "./views/main/models/labelcontainer/PreviewMultiLabel.vue"
                                        ),
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },

                        {
                          path: "label",
                          name: "labeling",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-label" */ "./views/main/models/LabelingView.vue"
                            ),
                          children: [
                            {
                              path: "labels/new",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/CreateLabel.vue"
                                ),
                            },
                            {
                              path: "labels/:labelid/update",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/UpdateLabel.vue"
                                ),
                            },
                            {
                              path: "labelcontainers/new",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/CreateLabelContainer.vue"
                                ),
                            },
                            {
                              path: "labelcontainers/:labelcontainerid",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabelconntainer" */ "./views/main/models/labelcontainer/UpdateLabelContainer.vue"
                                ),
                              children: [
                                {
                                  path: "labels/:labelid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/models/labelcontainer/UpdateLabel.vue"
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "datasets",
                  component: () =>
                    import(/* webpackChunkName: "model" */ "./views/main/datasets/Datasets.vue"),
                  redirect: ":workspaceid/datasets/view",
                  children: [
                    {
                      path: "view",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/datasets/DatasetsView.vue"
                        ),
                    },
                    {
                      path: "upload",
                      component: () =>
                        import(
                          /* webpackChunkName: "model-view" */ "./views/main/datasets/DatasetsUpload.vue"
                        ),
                    },
                    {
                      path: ":id",
                      component: () =>
                        import(/* webpackChunkName: "model" */ "./views/main/datasets/Dataset.vue"),
                      redirect: ":workspaceid/datasets/:id/dashboard",
                      children: [
                        {
                          path: "dashboard",
                          component: () =>
                            import(
                              /* webpackChunkName: "model-label" */ "./views/main/datasets/DatasetView.vue"
                            ),
                          children: [
                            {
                              path: "settings",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-view" */ "./views/main/datasets/UpdateDataset.vue"
                                ),
                              children: [
                                {
                                  path: "inferenceprompts/:inferencepromptid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/UpdateInferencePrompt.vue"
                                    ),
                                },
                                {
                                  path: "inferenceprompts",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/CreateInferencePrompt.vue"
                                    ),
                                },
                              ],
                            },
                            {
                              path: "dashboard",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/Dashboard.vue"
                                ),
                            },
                            {
                              path: "browser",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/BrowseDemo.vue"
                                ),
                            },
                            {
                              path: "browse",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/Browse.vue"
                                ),
                            },
                            {
                              path: "conversationview",
                              component: () => import(/* webpackChunkName: "login" */ "./views/main/superanalytics/ConversationView.vue"),
                              meta: {
                                hideNavbar: true,
                               }                              
                            },
                            {
                              path: "graphs",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/Graphs.vue"
                                ),
                              children: [
                                {
                                  path: "model/:modelid/setup",
                                  component: () => import("./views/main/datasets/csat/Setup.vue"),
                                },
                                {
                                  path: "model/:modelid/csat",
                                  component: () => import("./views/main/datasets/csat/Graph.vue"),
                                },
                                {
                                  path: "model/:modelid/csat/category/:categoryid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/csat/GraphCategory.vue"
                                    ),
                                },
                                {
                                  path: "model/:modelid/csat/subcategory",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/csat/GraphCluster.vue"
                                    ),
                                },
                                {
                                  path: "model/:modelid/time",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/time/TimeGraph.vue"
                                    ),
                                },
                                {
                                  path: "model/:modelid/time/category/:categoryid",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/time/TimeGraphCategory.vue"
                                    ),
                                },
                                {
                                  path: "model/:modelid/time/subcategory",
                                  component: () =>
                                    import(
                                      /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/time/TimeGraphCluster.vue"
                                    ),
                                },
                              ],
                            },
                            {
                              path: "search",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/Search.vue"
                                ),
                            },
                            {
                              path: "cluster-discovery",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/ClusterDiscovery.vue"
                                ),
                            },
                            {
                              path: "tags",
                              component: () =>
                                import(
                                  /* webpackChunkName: "model-newlabel" */ "./views/main/datasets/Tags.vue"
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "admin",
              component: () =>
                import(/* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"),
              redirect: "",
              children: [
                {
                  path: "cortex",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ "./views/main/admin/Cortex.vue"
                    ),
                },
                {
                  path: "users",
                  redirect: "users/all",
                },
                {
                  path: "users/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ "./views/main/admin/AdminUsers.vue"
                    ),
                },
                {
                  path: "users/edit/:id",
                  name: "main-admin-users-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ "./views/main/admin/EditUser.vue"
                    ),
                },
                {
                  path: "users/create",
                  name: "main-admin-users-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ "./views/main/admin/CreateUser.vue"
                    ),
                },
                {
                  path: "subscription-plans",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ "./views/main/admin/AdminSubscriptionPlans.vue"
                    ),
                },
                {
                  path: "subscription-plans/plans",
                  component: () =>
                    import(
                      /* webpackChunkName: "model-preview" */ "./views/main/admin/ManagePlans.vue"
                    ),
                },
                {
                  path: "subscription-plans/subscriptions",
                  component: () =>
                    import(
                      /* webpackChunkName: "model-preview" */ "./views/main/admin/ManageWorkspaceSubscriptions.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
  scrollBehavior() {
    document!.getElementById("app")!.scrollIntoView();
  },
});
